.table {
  border-color: var(--gray-40);

  & > :not(:first-child) {
    border-top: 0;
  }
  tr:last-child {
    border-bottom: 0 solid white;
  }
}

.business-customer-name {
  min-width: 60%;
}

@media (max-width: 1540px) {
  .customer-details {
    margin: auto;
  }
}
